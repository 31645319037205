/**
 * Theme site.js
 */

function init() {
    'use strict';
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    /*$(document).ready(function () {
        let urlParams = new URLSearchParams(window.location.search);
        let province = urlParams.get('province');
        let service = urlParams.get('service');

        if (province) {
            document.getElementById('province-select').querySelector("option[value='" + province + "']").selected = true;
        }

        if (service) {
            document.getElementById('service-select').querySelector("option[value='" + service + "']").selected = true;
        }

        $('select#province-select').change(function () {
            updateURL('province', $(this).val());
        });

        $('select#service-select').change(function () {
            updateURL('service', $(this).val());
        });
    });

    function updateURL(param, value) {
        let urlParams = new URLSearchParams(window.location.search);
        if (value) {
            urlParams.set(param, value);
        } else {
            urlParams.delete(param);
        }
        window.location.search = urlParams.toString();
    }*/

})(jQuery);
